import { BankAccountType } from '@/ConstValues';
import { Address } from './AddressModel';
import { AddressRequestModel } from './RequestModels';
import { ConversionHelper } from '@/helpers/ConversionHelper';

export class CustomerPaymentModel {
  NameOnCard!: string;
  CreditCardNumber!: string;
  ExpirationDate: string = '';
  CCV!: string;
  BillingAddressId!: string;
  AddressToEdit: Address = new Address();
  IsTestingAddress!: boolean;
  Address!: AddressRequestModel;
  CustomerId: number;
  AccountNumber: string;
  AccountType: BankAccountType;
  BankName: string;
  RoutingNumber: string;
  PaymentType: string;
  IsBankAccount: boolean;
  constructor() {
    this.PaymentType = 'Credit Card';
    this.AccountType = BankAccountType.BusinessChecking;
  }
  IsABankAccount: () => boolean = () => {
    return this.PaymentType == 'Bank Account';
  };
  ConvertToRequestModel: () => any = () => {
    var model = {
      NameOnCard: this.NameOnCard,
      CreditCardNumber: this.CreditCardNumber,
      ExpirationDate: this.ExpirationDate,
      CCV: this.CCV,
      BillingAddressId: this.BillingAddressId,
      AccountNumber: this.AccountNumber,
      AccountType: this.AccountType,
      BankName: this.BankName,
      RoutingNumber: this.RoutingNumber,
      IsBankAccount: this.IsABankAccount(),
      Address: ConversionHelper.convertAddressToAddressModel(
        this.AddressToEdit,
        true,
        true,
        false
      )
    };
    return model;
  };
  ConvertToStripeRequestModel: () => any = () => {
    var model = {
      type: 'card',
      card: {
        number: this.CreditCardNumber,
        exp_month: '01',
        exp_year: '2026',
        cvc: this.CCV
      },
      billing_details: {
        name: this.NameOnCard,
        email: '',
        address: {
          line1: this.Address.Street1,
          line2: this.Address.Street2,
          city: this.Address.City,
          state: this.Address.State,
          postal_code: this.Address.Zipcode,
          country: 'US'
        }
      }
    };
    return model;
  };
}

export class PaymentProfile {
  CanEdit!: boolean;
  CardName!: string;
  Avatar!: string;
  CardNumber!: string;
  CardType!: number;
  ExpirationDate!: string;
  IsDefault!: boolean;
  IsDefaultPaymentMethodForInvoices!: boolean;
  IsExpired!: boolean;
  IsLinkedToChargeAutomatically: boolean;
  IsLoading: boolean;
  IsNet!: boolean;
  NameOnCard!: string;
  PaymentAddress!: Address;
  PaymentMethodDescription!: string;
  PaymentMethodTitle!: string;
  PaymentMethodType!: number;
  PaymentProfileId!: string;
  Id!: string;
  PublicId!: string;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.CanEdit = rawItem.CanEdit;
      this.CardName = rawItem.CardProvider;
      this.CardNumber = rawItem.CardNumber;
      this.CardType = rawItem.CardType;
      this.ExpirationDate = rawItem.ExpirationDate;
      this.IsExpired = rawItem.IsExpired;
      this.IsDefault = rawItem.IsDefault;
      this.IsDefaultPaymentMethodForInvoices = rawItem.IsDefaultPaymentMethodForInvoices;
      this.IsLinkedToChargeAutomatically = rawItem.IsLinkedToChargeAutomatically;
      this.IsNet = rawItem.IsNet;
      this.NameOnCard = rawItem.NameOnCard;
      this.PaymentAddress = new Address(rawItem.PaymentAddress);
      this.PaymentMethodDescription = rawItem.PaymentMethodDescription;
      this.PaymentMethodTitle = rawItem.PaymentMethodTitle;
      this.PaymentMethodType = rawItem.PaymentMethodType;
      this.PaymentProfileId = rawItem.PaymentProfileId;
      this.Id = rawItem.PublicId;
      this.PublicId = rawItem.PublicId;
    }
  }
  IsABankAccount: () => boolean = () => {
    return this.CardType == 8;
  };
}
