import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
const paymentUrl = `${process.env.VUE_APP_ROOT_API}/Payment`;

export const PaymentService = {
  CanDeletePaymentProfile(paymentProfileId: string): Promise<AxiosResponse> {
    return axios.get(
      `${paymentUrl}/CanDeletePaymentProfile?paymentProfileId=${paymentProfileId}`
    );
  },
  ConfirmSetupIntent(): Promise<AxiosResponse> {
    return axios.post(`${paymentUrl}/ConfirmSetupIntent`);
  },
  CreateSetupIntent(): Promise<AxiosResponse> {
    return axios.post(`${paymentUrl}/CreateSetupIntent`);
  },
  GetCustomerPaymentProfilesSelfPay() {
    return axios.get(`${paymentUrl}/GetCustomerPaymentProfilesSelfPay`);
  },
  ChargeCard(invoiceId: string, paymentProfileId: string) {
    return axios.get(
      `${paymentUrl}/ChargeCard?invoiceId=${invoiceId}&paymentProfileId=${paymentProfileId}`
    );
  },
  GetAdditionalCustomerPaymentProfiles(customerId: number = 0): Promise<AxiosResponse> {
    return axios.get(
      `${paymentUrl}/GetAdditionalCustomerPaymentProfiles?customerId=${customerId}`
    );
  },
  GetCustomerPaymentProfiles(customerId: number = 0): Promise<AxiosResponse> {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get(
        `${paymentUrl}/GetCustomerPaymentProfiles?customerId=${customerId}`
      );
      const { data } = await axios.get(
        `${paymentUrl}/GetAdditionalCustomerPaymentProfiles?customerId=${customerId}`
      );
      if (data && data.length > 0) {
        response.data = response.data.concat(data);
      }
      resolve(response);
    });
  },
  SavePaymentMethod(model: Object) {
    return axios.post(`${paymentUrl}/SavePaymentMethod`, model);
  },
  SaveNewPaymentMethod(model: any): Promise<AxiosResponse> {
    return axios.post(`${paymentUrl}/SaveNewPaymentMethod`, model);
  },
  SetDefaultPaymentMethod(paymentId: string) {
    return axios.get(`${paymentUrl}/SetDefaultPaymentMethod?paymentId=${paymentId}`);
  },
  PayInvoice(paymentProfileId: string, invoiceId: string): Promise<AxiosResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        var model = {
          paymentId: paymentProfileId,
          invoiceId: invoiceId
        };
        const response = await axios.post(`${paymentUrl}/PayInvoice`, model);
        handleResponse(response);
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }
};
