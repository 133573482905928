
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import {
  Stripe,
  loadStripe,
  StripeElements,
  StripeElement,
  StripeError,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { PaymentService } from '@/services/payment-service';

@Component
export default class StripePaymentComponent extends Vue {
  $refs!: {
    paymentElement: HTMLElement;
  };
  @Prop({ default: true }) canUseBankAccount!: boolean;
  stripePromise: Promise<Stripe | null> = loadStripe(process.env.VUE_APP_STRIPE_SK);
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  paymentElement: StripeElement = null;
  isLoading: boolean = false;
  // us_bank_account
  async mounted() {
    this.stripe = await this.stripePromise;
    if (this.stripe) {
      this.elements = this.stripe.elements({
        mode: 'setup',
        currency: 'usd'
      });
      let paymentMethodTypes = ['card'];
      if (this.canUseBankAccount) {
        paymentMethodTypes.push('us_bank_account');
      }
      this.paymentElement = this.elements.create('payment', {
        paymentMethodOrder: paymentMethodTypes
      } as StripePaymentElementOptions);
      this.elements.update({ payment_method_types: paymentMethodTypes });
      this.$nextTick(() => {
        this.paymentElement.mount(this.$refs.paymentElement);
      });
    }
  }

  handleError(error: StripeError) {
    const messageContainer = document.querySelector('#error-message');
    messageContainer.textContent = error.message;
  }

  async submitCard() {
    this.isLoading = true;
    try {
      if (!this.stripe || !this.paymentElement) {
        console.error('Stripe.js has not loaded yet.');
        this.isLoading = false;
        return;
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await this.elements.submit();
      if (submitError) {
        this.handleError(submitError);
        this.isLoading = false;
        return;
      }

      const { data: clientSecret } = await PaymentService.CreateSetupIntent();

      const { error } = await this.stripe.confirmSetup({
        elements: this.elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.href
        }
      });

      if (error) {
        this.handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }
}
