
import { PaymentProfile } from '@/store/models/PaymentModel';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class PaymentSelectBankAccount extends Vue {
  /* Properties */
  @Prop({ required: true }) profile!: PaymentProfile;
  @Prop() width!: number;
  @Prop({ default: true }) shouldHover!: boolean;
  @Prop() isFlat!: boolean;
  @Prop() shouldShowDescription!: boolean;
  @Prop({ default: false }) isReadOnly!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  get cardWidth() {
    return this.width || 480;
  }
  /* Async Functions */
  /* Utility Functions */
  getCardName(profile: PaymentProfile) {
    switch (profile.CardType) {
      case 1:
        return 'Visa';
      case 2:
        return 'MasterCard';
      case 3:
        return 'American Express';
      case 4:
        return 'Diners Club';
      case 5:
        return 'Discover';
      case 6:
        return 'JCB';
      case 7:
        return profile.NameOnCard?.replace(/\s/g, '');
      case 8:
        return profile.NameOnCard;
    }
    return 'Unknown Card.';
  }
  getCardType(profile: PaymentProfile) {
    switch (profile.CardType) {
      case -1:
      case 8:
        return 'Account';
      default:
        return 'Card';
    }
  }
  isValidDate(date: string) {
    const [month, year] = date.split('/');
    const todaysDate = new Date();
    let displayYear = year.length > 2 ? year : `20${year}`;
    if (displayYear === todaysDate.getFullYear().toString())
      return parseInt(month) >= todaysDate.getMonth() + 1;
    if (displayYear > todaysDate.getFullYear().toString()) return true;
    return false;
  }
  goToMicodepositUrl() {
    window.onfocus = async () => {
      const reRoute = () => {
        if (!this.$route.query.wallet) {
          this.$router.replace({ query: { wallet: 'true' } });
        }
        location.reload(true);
      };
      reRoute();
    };
    window.open(this.profile.CardNumber, '_blank');
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
